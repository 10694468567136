/**
 * Binding to passive events
 * https://angular.io/guide/event-binding#binding-to-passive-events
 */
(window as any)['__zone_symbol__PASSIVE_EVENTS'] = [
    'scroll',
    'wheel',
    'mousewheel',
    'touchstart',
    'touch',
    'touchmove',
    'touchend',
    'MSPointerDown',
    'mousedown',
    'mousemove',
    'pointerdown',
    'pointermove',
];